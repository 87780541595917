<template>
  <b-container fluid>
    <b-container>
      <b-row class='justify-content-center'>
        <b-col lg='4'>
          <h1 class='h3 mt-5 mb-3 text-center'>{{$t('completePassword.header')}}</h1>
          <b-form @submit='onSubmit'>
            <b-form-group>
              <b-form-input type='password'
                            required
                            :placeholder='$t("completePassword.placeholder.password")'
                            v-model='password'></b-form-input>
            </b-form-group>
            <b-form-group>
              <b-form-input type='password'
                            required
                            :placeholder='$t("completePassword.placeholder.confirmPassword")'
                            v-model='confirmPassword'></b-form-input>
            </b-form-group>
            <b-form-group>
              <b-button variant='primary'
                        size='sm'
                        type='submit'
                        :disabled='loading'>
                <span class='spinner-border spinner-border-sm' v-if='loading'></span>
                {{$t('completePassword.button.submit')}}
              </b-button>
            </b-form-group>
            <b-alert variant='danger'
                     :show='!!error'
                     class='py-2 text-small'>
              {{error}}
            </b-alert>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
import { Auth } from 'aws-amplify'
import _ from 'lodash'

export default {
  name: 'CompletePassword',
  data () {
    return {
      loading: false,
      password: null,
      confirmPassword: null,
      error: null
    }
  },
  methods: {
    onSubmit (e) {
      e.preventDefault()
      this.error = this.validation()

      if (this.error) return

      this.loading = true
      const loggedInUser = _.cloneDeep(this.$store.getters.loggedInUser)
      Auth.completeNewPassword(loggedInUser, this.password, {})
        .then(user => {
          this.loading = false
          this.$store.commit('setLoggedInUser', user)
          this.$store.commit('setHasToken', true)
          this.$router.push('/')
        })
        .catch(err => {
          this.loading = false
          this.error = this.$t(`error.${err.code}`)
        })
    },
    validation () {
      if (this.password !== this.confirmPassword) {
        return this.$t('error.notMatchPassword')
      }
      return null
    }
  }
}
</script>
